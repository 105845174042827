import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import santaPhotosStyle from "./socially-distant-santa-photos.module.css";
import SantaPhotosShootSkuCardSkuCard from "../components/Products/santaPhotosShootSkuCard";
// import addToMailchimp from "gatsby-plugin-mailchimp";
import addToMailchimp from "../services/mailchimp";
import env from "../config/env";
import becomeAMemberStyle from "./become-a-member.module.css";

const SociallyDistantSantaPhotosPage = ({data}) => {

  const discountCodes = ['MOMSCODE', 'KONAKAICODE', 'LEXIECODE'];

  const submitSantaPhotoShootUrl = env.submitSantaPhotoShootUrl;

  const [showFieldsForm, setShowFieldsForm] = useState( false);
  const [hideBookNowButton, setHideBookNowButton] = useState( false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState({ show: false, message: "" });
  const [mailchimpAddMemberShowError, setMailchimpAddMemberShowErrorAlert] = useState({ show: false, message: "" });
  const [formResult, setFormResult] = useState({});
  const [sku, setSku] = useState(null);
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showNameErrorMessage, setShowNameErrorMessage] = useState({ show: false, message: "" });
  const [showDateTimeErrorMessage, setShowDateTimeErrorMessage] = useState({ show: false, message: "" });
  const [discountCodeIsValid, setDiscountCodeValidity] = useState(false);
  const [hasVerifiedDiscountCode, setHasVerifiedDiscountCode] = useState(false);

  useEffect(() => {
    updateSku();
  }, [discountCodeIsValid]);

  const [formState, setFormValues] = useState({
    firstDate: "",
    firstHour: "",
    secondDate: "",
    secondHour: "",
    quantity: 1,
    phone: "",
    email: "",
    name: "",
    discountCode: "",
  });

  const handleRegisterPhotosSantaFormSubmit = async e => {
    e.preventDefault();
    try {

    } catch (error) {
      console.log(error);
    }
  };

  const handleRegisterPhotosWithSantaFormChange = e => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    })
  };

  const showStripeErrorMessage = showStripeErrorMessage => {
    setStripeErrorMessage({
      show: true,
      message: showStripeErrorMessage,
    })
  };

  const validateChoicesForm = () => {
    let isValid = true;

    if (!formState.firstDate || !formState.firstHour) {
      isValid = false;
      setShowDateTimeErrorMessage({
        show: true,
        message: "Please select at least one date and one hour!"
      })
    }

    if (formState.secondDate && !formState.secondHour || formState.secondHour && !formState.secondDate) {
      isValid = false;

      setShowDateTimeErrorMessage({
        show: true,
        message: "Please select at least one date!"
      })
    }
    return isValid
  };

  const validateUserDetailsForm = () => {
    let isValid = true;

    if (!formState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email address!"
      })
    }

    if (!formState.name) {
      isValid = false;
      setShowNameErrorMessage({
        show: true,
        message: "Please enter your name!"
      })
    }

    return isValid;
  };

  const saveBookingToMailchimp = async () => {
    const result = await addToMailchimp(formState.email, {
        FIRSTDATE: formState.firstDate,
        FIRSTHOUR: formState.firstHour,
        SECONDDATE: formState.secondDate,
        SECONDHOUR: formState.secondHour,
        PHONE: formState.phone,
        NAME: formState.name
      }, submitSantaPhotoShootUrl
    );
    setFormResult(result);
    return true;
  };

  const validateAndSaveData = async () => {
    return validateUserDetailsForm() && await saveBookingToMailchimp();
  };

  const updateSku = () => {
    if (discountCodeIsValid) {
      data.skus.edges.map(edge => {
        let productName = "Santa Family Photo Shoot Discount";
        if (productName === edge.node.product.name) {
          setSku(edge.node);
        }
      });
    } else {
      data.skus.edges.map(edge => {
        let productName = "Santa Family Photo Shoot";
        if (productName === edge.node.product.name) {
          setSku(edge.node);
        }
      });
    }
  };

  const onBookNow = async () => {
    updateSku();

    const isValid = validateChoicesForm();

    if (!isValid) {
      return;
    }

    setShowFieldsForm({
      showFieldsForm: true
    });
    setHideBookNowButton({
      hideBookNowButton:false
    });
  };

  const verifyDiscountCode = () => {
    if (discountCodes.indexOf(formState.discountCode) > -1) {
      setDiscountCodeValidity(true);
    } else {
      setDiscountCodeValidity(false);
    }
    setHasVerifiedDiscountCode(true);
  };

  return (
    <Layout>
      <Container>

        <div>
          <div className="title">
            <h1 className={globalStyle.entryTitle}>Holiday Cheer is On. Join us for our Socially Distant Santa Family Photo Shoot</h1>
            <p className={globalStyle.boldStyle}>with Moms on Maternity and the Kona Kai San Diego</p>
          </div>

          <div>
            <Row className={santaPhotosStyle.rowStyle}>
              <Col xs={12} md={6} sm={12}>
                <Row>
                  <Col>
                    <video width="100%" height="auto" autoPlay loop muted playsInline>
                      <source src={data.christmasVideo2.publicURL} type="video/mp4"/>
                    </video>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} sm={6}>
                <div className={santaPhotosStyle.about}>
                  <h5><i className={[santaPhotosStyle.icon, "fa fa-camera"].join(" ")} aria-hidden="true"></i> Three professionally
                    taken and edited photos by
                    <a className={globalStyle.redirectLink} href="https://photographybylexie.godaddysites.com/" target="_blank" rel="noopener noreferrer"> LC Photography</a>
                  </h5>

                  <h5><i className={[santaPhotosStyle.icon, "fa fa-shopping-bag"].join(" ")} aria-hidden="true"></i> Swag bag of goodies</h5>

                  <h5><i className={[santaPhotosStyle.icon, "fas fa-shoe-prints fa-rotate-270"].join(" ")}></i> Meet and greet with Santa Claus</h5>

                  <h5><i className={[santaPhotosStyle.icon, "fa fa-clock"].join(" ")}></i> No line to wait in</h5>

                  <h5><i className={[santaPhotosStyle.icon, "fas fa-parking"].join(" ")}></i> Validated parking at the Kona Kai included</h5>
                </div>

                <div className={santaPhotosStyle.nextRow}>
                  <i className="fa fa-angle-double-down"></i>
                </div>
              </Col>
            </Row>

            <Row className={santaPhotosStyle.rowStyle}>
              <Col xs={12} md={6} sm={6}>
                <div className={santaPhotosStyle.periodTitle}>
                  <h4>Three Sundays:</h4>
                  <i className={[santaPhotosStyle.icon, "fa fa-calendar"].join(" ")} aria-hidden="true">
                    <span className={santaPhotosStyle.eventDate}>November 22</span>
                  </i><br />
                  <i className={[santaPhotosStyle.icon, "fa fa-calendar"].join(" ")} aria-hidden="true">
                    <span className={santaPhotosStyle.eventDate}>November 29</span>
                  </i><br />
                  <i className={[santaPhotosStyle.icon, "fa fa-calendar"].join(" ")} aria-hidden="true" style={{"paddingRight": "20px"}}>
                    <span className={santaPhotosStyle.eventDate}>December 6</span>
                  </i><br />
                </div>

                <div className={santaPhotosStyle.nextRow}>
                  <i className="fa fa-angle-double-down"></i>
                </div>
              </Col>
              <Col xs={12} md={6} sm={6}>
                <video width="100%" height="auto" autoPlay loop muted playsInline>
                  <source src={data.christmasVideo3.publicURL} type="video/mp4"/>
                </video>
              </Col>
            </Row>

            <Row className={santaPhotosStyle.rowStyle}>
              <Col xs={12} md={6} sm={6}>
                <video width="100%" height="auto" autoPlay loop muted playsInline>
                  <source src={data.christmasVideo1.publicURL} type="video/mp4"/>
                </video>

                <div className={santaPhotosStyle.nextRow}>
                  <i className="fa fa-angle-double-down"></i>
                </div>
              </Col>

              <Col xs={12} md={6} sm={6}>
                <ul className={santaPhotosStyle.aboutDetails}>
                  <li>Package includes 3 professionally taken and edited photos by <a className={globalStyle.redirectLink} href="https://photographybylexie.godaddysites.com/" target="_blank" rel="noopener noreferrer">LC Photography</a>. This allows for three fun family poses or one with the whole fam, one with just the kids and one with just the adults. Photos will be sent within 48 hours of shoot. One 8x10 of your shot choice will be printed and mailed to you in exchange for permission for LC Photography to use the photo on their website and/or social gallery.</li>

                  <li>To ensure proper social distancing, there will be no line to wait in. Families must have booking confirmed; this is by appointment only.</li>

                  <li>Take home a swag bag of goodies including a Tank Top from Moms on Maternity, Moms on Maternity Membership and so much more from our sponsors.</li>

                  <li>Stay on property and dine with the family at the Gorgeous Vessel Restaurant with a 10% discount with photo package. Check out Kona Kai’s Private Beach while you are there and let the kids run around!</li>

                  <li>Validated parking at the Kona Kai included</li>

                  <li>Questions? Contact <a className={globalStyle.redirectLink} href="mailto:AimeeCruz@MomsOnMaternity.com">AimeeCruz@MomsOnMaternity.com</a></li>
                </ul>
              </Col>
            </Row>

            <Row className={santaPhotosStyle.rowStyle}>
              <Col xs={12} md={6} sm={6}>

                <Row className={santaPhotosStyle.bannerContent}>
                  <Col className={santaPhotosStyle.bannerColumn}>
                    <Row className={santaPhotosStyle.price}>
                      <Col>
                        <p> Book now! </p>
                      </Col>
                    </Row>

                    <Row className={santaPhotosStyle.memberBenefitsVideo}>
                      <h4 className={santaPhotosStyle.description}>
                        Choose your desired window for your Family Photo with Santa
                      </h4>
                    </Row>

                    <Form
                          onSubmit={handleRegisterPhotosSantaFormSubmit}
                          name="registerPhotosWithSanta">

                      <Form.Row>
                        <h5>Select your first choice<span className={globalStyle.required}></span></h5>
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formFirstDate" className={santaPhotosStyle.registerFormGroup}>
                            <Form.Control as="select"
                                          onChange={handleRegisterPhotosWithSantaFormChange}
                                          value={formState.firstDate}
                                          placeholder="Select your first choice" name="firstDate">
                              <option className="defaultValue" value="">Select date</option>
                              <option>22 November</option>
                              <option>29 November</option>
                              <option>6 December</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formFirstHour" className={santaPhotosStyle.registerFormGroup}>
                            <Form.Control as="select"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                              value={formState.firstHour}
                                          placeholder="Select your first choice" name="firstHour">
                              <option className="defaultValue" value="">Select hour</option>
                              <option>12-1</option>
                              <option>1-2</option>
                              <option>2-3</option>
                              <option>3-4</option>
                              <option>4-5</option>
                              <option>6-7</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={12} sm={12}>
                        {showDateTimeErrorMessage.show && (
                          <Row className={becomeAMemberStyle.showError}>
                              <Col>
                                <Alert
                                  variant="danger"
                                  onClose={() => setShowDateTimeErrorMessage({ show: false })}
                                  dismissible
                                >
                                  {showDateTimeErrorMessage.message}
                                </Alert>
                            </Col>
                          </Row>
                        )}
                        </Col>

                        <h5>Select your second choice</h5>
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formSecondDate" className={santaPhotosStyle.registerFormGroup}>
                            <Form.Control as="select"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                              value={formState.secondDate}
                                          placeholder="Select your first choice" name="secondDate">
                              <option className="defaultValue" value="">Select date</option>
                              <option>22 November</option>
                              <option>29 November</option>
                              <option>6 December</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formSecondHour" className={santaPhotosStyle.registerFormGroup}>
                            <Form.Control as="select"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                              value={formState.secondHour}
                                          placeholder="Select your first choice" name="secondHour">
                              <option className="defaultValue" value="">Select hour</option>
                              <option>12-1</option>
                              <option>1-2</option>
                              <option>2-3</option>
                              <option>3-4</option>
                              <option>4-5</option>
                              <option>6-7</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Row>
                          <Col>
                            <p className={santaPhotosStyle.noteContent}>
                              <span className={globalStyle.required}></span>
                              <span className={globalStyle.required}></span>
                              <span className={globalStyle.required}></span>
                              we will confirm your 15 minute appointment slot within 24 hours.
                            </p>
                          </Col>
                        </Row>

                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formName" className={santaPhotosStyle.registerFormGroup}>
                            <Form.Control
                              size="sm"
                              value={formState.discountCode}
                              name="discountCode"
                              type="text"
                              placeholder="Have a discount code?"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                              onBlur={verifyDiscountCode}
                            />
                            {hasVerifiedDiscountCode && discountCodeIsValid &&
                            <i className={[santaPhotosStyle.doneIcon, "far fa-check-circle"].join(" ")}></i>
                            }
                            { hasVerifiedDiscountCode && !discountCodeIsValid &&
                              <p className={santaPhotosStyle.warning}>You have used an invalid discount code!</p>
                            }
                          </Form.Group>
                        </Col>

                        {!hideBookNowButton &&
                          <Button
                            className={[
                              globalStyle.btnPrimary,
                              santaPhotosStyle.goldButton,
                              santaPhotosStyle.bookNowButton
                            ]}
                            onClick={onBookNow}
                          >
                            BOOK NOW!
                          </Button>
                        }

                        {showFieldsForm &&
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formName" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                            <Form.Label >Name <span className={globalStyle.required}></span></Form.Label>
                            <Form.Control
                              size="sm"
                              value={formState.name}
                              name="name"
                              type="text"
                              placeholder="Please enter your name"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                            />
                          </Form.Group>
                        </Col>
                        }

                        <Col xs={12} md={12} sm={12}>
                          {showNameErrorMessage.show && (
                            <Row className={becomeAMemberStyle.showError}>
                              <Col>
                                <Alert
                                  variant="danger"
                                  onClose={() => setShowNameErrorMessage({ show: false })}
                                  dismissible
                                >
                                  {showNameErrorMessage.message}
                                </Alert>
                              </Col>
                            </Row>
                          )}
                        </Col>

                        {showFieldsForm &&
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formEmail" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                            <Form.Label >Email <span className={globalStyle.required}></span></Form.Label>
                            <Form.Control
                              size="sm"
                              value={formState.email}
                              name="email"
                              type="text"
                              placeholder="Please enter your email"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                            />
                          </Form.Group>
                        </Col>
                        }

                        <Col xs={12} md={12} sm={12}>
                          {showEmailErrorMessage.show && (
                            <Row className={becomeAMemberStyle.showError}>
                              <Col>
                                <Alert
                                  variant="danger"
                                  onClose={() => setShowEmailErrorMessage({ show: false })}
                                  dismissible
                                >
                                  {showEmailErrorMessage.message}
                                </Alert>
                              </Col>
                            </Row>
                          )}
                        </Col>

                        {showFieldsForm &&
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formPhone" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                            <Form.Label >Phone </Form.Label>
                            <Form.Control
                              size="sm"
                              value={formState.phone}
                              name="phone"
                              type="text"
                              placeholder="Please enter your phone number"
                              onChange={handleRegisterPhotosWithSantaFormChange}
                            />
                          </Form.Group>
                        </Col>
                        }

                        { showFieldsForm &&
                        <Col xs={12} md={12} sm={12} className={santaPhotosStyle.checkoutButton}>
                          <SantaPhotosShootSkuCardSkuCard
                            quantity={formState.quantity}
                            email={formState.email}
                            sku={sku}
                            beforeRedirect={validateAndSaveData}
                            showStripeErrorMessage={showStripeErrorMessage}
                            path="/socially-distant-santa-photos"
                            discountCodeIsValid={discountCodeIsValid}
                          />
                        </Col>
                        }

                      </Form.Row>
                    </Form>

                    <Row className={santaPhotosStyle.price}>
                      <Col>
                        <p>{discountCodeIsValid && "$24.5" } {!discountCodeIsValid && "$49"} Get Your Family Photo With Santa</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </Col>

              <Col xs={12} md={6} sm={6}>
                <video width="100%" height="auto" autoPlay muted loop playsInline>
                  <source src={data.christmasVideo4.publicURL} type="video/mp4"/>
                </video>
              </Col>

            </Row>

            <Row className={santaPhotosStyle.rowStyle}>
              <Col>
                <h4>Thank you to our event sponsors who are providing gifts for our early bookings and/or swag bags!</h4>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} sm={6}>
                <ul>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://chefv.com/" target="_blank" rel="noopener noreferrer">Chef V</a>
                  </li>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://cariniarts.com/" target="_blank" rel="noopener noreferrer">Michael Carini Arts</a>
                  </li>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://blyssen.com/" target="_blank" rel="noopener noreferrer">Blyssen Skincare</a>
                  </li>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://tinytruckerco.com/" target="_blank" rel="noopener noreferrer">Tiny Trucker Co</a>
                  </li>
                </ul>
              </Col>

              <Col xs={12} md={6} sm={6}>
                <ul>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://getpurebloom.com/" target="_blank" rel="noopener noreferrer">Pure Bloom CBD</a>
                  </li>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://busyeasytoys.com/" target="_blank" rel="noopener noreferrer">Busy Easy Cube</a>
                  </li>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://www.vesselkitchen.com/" target="_blank" rel="noopener noreferrer">Vessel Restaurant </a>
                  </li>
                  <li className={santaPhotosStyle.sponsorsItem}>
                    <a className={santaPhotosStyle.sponsors} href="https://www.parravijewelry.com/" target="_blank" rel="noopener noreferrer">Parravi Jewelry</a>
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className={santaPhotosStyle.rowStyle}>
              <Col>
                <p>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  COVID Information: The Kona Kai is a leader in ensuring adherence to safety protocols.
                  Your health is our utmost priority and concern. The Kona Kai Resort & Spa team is committed to protecting
                  the wellbeing of our guests and team members by providing a comfortable and safe environment for
                  everyone who visits our property. We are advancing our current best practices and making sure our teams
                  take extra precautions in order to deliver a fulfilling and enjoyable travel experience.
                  Please read more on protocols <a className={globalStyle.redirectLink} href={"https://www.resortkonakai.com/stay-safe-start-living/"} target="_blank" rel="noopener noreferrer">here</a>.</p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    busyEasyCube: allFile(filter: {relativePath: {regex: "/market/partners-images/busy-easy-cube\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    arrowDown: file(
      relativePath: { eq: "santa-photos/arrow-down.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    christmasVideo1: file(
      relativePath: {eq: "santa-photos/christmas-video1-remux.mp4"}
    ) {
        publicURL
        name
    } 
    christmasVideo2: file(
      relativePath: {eq: "santa-photos/christmas-video2-remux.mp4"}
    ) {
        publicURL
        name
    } 
    christmasVideo3: file(
      relativePath: {eq: "santa-photos/christmas-video3-remux.mp4"}
    ) {
        publicURL
        name
    } 
    christmasVideo4: file(
      relativePath: {eq: "santa-photos/christmas-video4-remux.mp4"}
    ) {
        publicURL
        name
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export default SociallyDistantSantaPhotosPage
